.bootstrap-select .bs-ok-default::after {
    width: 0.3em;
    height: 0.6em;
    border-width: 0 0.1em 0.1em 0;
    transform: rotate(45deg) translateY(0.5rem);
}

.btn.dropdown-toggle:focus {
    outline: none !important;
}

.save-button span {
    padding-left: 12px;
}

.p-id {
    padding: 6px 12px 6px 12px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
  resize: none;
}

@media print {
    #details-content * {
        page-break-inside: avoid;
    }
}




.imageupload {
    background-color: white;
    width: 200px;
    height: 200px;
    font-size: 32px;
    padding: calc(200px/2 - 16px);
    border: 1px solid darkblue;
    border-radius: 10px;
}

.imageupload:hover {
    background-color: aliceblue;
    cursor: pointer;
}
.imageupload[disabled=""] {
    background-color: #ccc;
    cursor: not-allowed;
}

.image-frame .btn.btn-delete {
    float: right;
    margin-bottom: 12px;
}

.image-frame > label {
    width: 100%;
}

.image-frame .choicesearch-fotograf > div {
    width: 100%;
}

.rowcontrol {
    float: right;
    padding-right: 15px
}

.edit-row > .well {
    padding: 12px;
    margin-bottom: 12px;
    border-color: #b3b3b3;
}

.edit-row .well .btn.btn-delete {
    float: right;
}

.well .form-control[type=date] {
    line-height: 20px; /* THIS IS A HACK, for some reason, Firefox seems to give this line-height=34px */
    width: auto;
}

#row-quelle .form-control {
    margin-bottom: 12px;
}

.row-choices {
    padding-bottom: calc(10px - 4px); /* 10px is default, due to the disposable texts it must be reduced */
}

.row-choices .disposable-text {
    padding: 0px 4px 4px 0px;
}

.row-choices .search {
    padding-left: 0px;
    padding-bottom: 4px;
}

.row-choices .elements {
    padding-left: 0px;
}

#row-material .disposable-text {
    padding: 0px 4px 4px 0px;
}

.state-row + .state-row {
    padding-top: 15px;
}

.edit-label-row.row {
    padding-left: 0px;
    padding-top: 0px;
    font-weight: bold;
}

#row-masse .col-md-12 .col-md-3:first-child {
    padding-left: 0px;
}

#row-masse .col-md-12 .col-md-3:last-child {
    padding-right: 0px;
}

.masse.col-md-10 {
    padding-left: 0px;
}

.masse div > div {
    padding-left: 15px;
    padding-right: 0px;
}

.zustaende .disposable-text {
    padding-bottom: 0px;
}

.col-md-1 + .col-md-7 {
    padding-left: 0px;
}

.col-md-7 + .col-md-4 {
    padding-left: 0px;
}

.col-md-6 + .col-md-6 {
    padding-left: 0px;
}

.dropdown-menu {
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
}

.row {
    padding-bottom: 10px;
}

.disposable-text {
    display: inline-block;
    padding: 0px;
    height: 34px;
}

.disposable-text .disposable-label {
    display: inline-block;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right-width: 0px;
    background-color: white;
}

.disposable-text .disposable-remove {
    position: relative;
    top: -1px; /* something with the glyphicon .... */
    padding: 7px 2px 5px 2px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px
}

.disposable-text .disposable-remove:focus {
    background-color: white;
    border: 1px solid #ccc;
}

.citation-linenumber div {
    width: 100%;
}

.citation-linenumber div button {
    width: 100%;
}

.col-md-3 .disposable-text {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}

.material-elements.col-md-7 {
    padding-left: 4px;
}

.material-elements.col-md-7 .disposable-text {
}

.material-search.col-md-2 {
    padding-right: 0px;
}

.material-search .choicesearch-material {
    width: 100%;
}

.material-search .choicesearch-material .dropdown {
    width: 100%;
}

.choicesearch-schadensbild .dropdown {
    width: 100%;
}

.row-choices .dropdown {
    width: 100%;
}

nav.navbar.navbar-default {
  background-color: #f1f3f8;
  border-color: #FFFFFF;
}

.show-grid.content {
    padding-bottom: 120px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f1f3f8;
}

/*.container {
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
}
*/

html {
  position: relative;
  min-height: 100%;
}

#body {
  margin-bottom: 120px; /* Margin bottom by footer height */
}

.checkbox {
  position: relative;
  margin-right: 10px;
  display: inline-block;

}

.navbar-global {
  background-color: #f1f3f8;
}

.navbar-global .navbar-brand {
  color: #000000;
}

.navbar-global .navbar-user > li > a
{
  color: #000000;
}

.navbar-primary {
  background-color: #f1f3f8;
  bottom: 0px;
  left: 0px;
  position: fixed; /* angepasst */
  top: 51px;
  width: 200px;
  z-index: 8;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.navbar-primary.collapsed {
  width: 60px;
}

.navbar-primary.collapsed .glyphicon {
  font-size: 22px;
}

.navbar-primary.collapsed .nav-label {
  display: none;
}

.btn-expand-collapse {
    position: absolute;
    display: block;
    left: 0px;
    bottom:0;
    width: 100%;
    padding: 8px 0;
    border-top:solid 1px #666;
    color: grey;
    font-size: 20px;
    text-align: center;
}

.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
    background-color: #222;
    color: white;
}

.btn-expand-collapse:active {
    background-color: #111;
}

.navbar-primary-menu,
.navbar-primary-menu li {
  margin:0; padding:0;
  list-style: none;
}

.navbar-primary-menu li a {
  display: block;
  padding: 10px 18px;
  text-align: left;
  border-top:solid 1px #ccc;
  color: #000000;
}

.navbar-primary-menu li a:hover {
  background-color: #000;
  text-decoration: none;
  color: white;
}

.navbar-primary-menu li a .glyphicon {
  margin-right: 6px;
}

.navbar-primary-menu li a:hover .glyphicon {
  color: orchid;
}

.main-content {
  margin-top: 60px;
  margin-left: 150px;
  padding: 20px;
}

.collapsed + .main-content {
  margin-left: 60px;
}

.choiceslistedit.container{
    display: inline-block;
}
.choiceslistedit .row {
    margin: 0px;
}
.choiceslistedit.container .row {
    display: flex;
}
.choiceslistedit.container .row > button {
    margin-left: 12px;
}

.choiceslistedit.container #add {
    background: lightgreen;
}
.choiceslistedit.container #edit {
    background: blanchedalmond;
}

.choiceslistedit.container .editentry.row > span {
    padding: 6px 12px 6px 0px;
}

#changepassword {
    width: 75%;
    margin-left: 12.5%;
}
#changepassword .form-control {
    width: 100%;
}
#registeruser .form-control[type=email] {
    width: 100%;
}
