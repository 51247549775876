/**

Style of version 3.0

**/
body {
	margin: 0;
	padding: 0;
	font-family: "Calibri", "Open Sans", "Arial";
	font-size: 18px;
	color: rgb(51, 51, 42);
}

/** HREF LINKS **/
a {
	color: rgb(51, 51, 42);
	text-decoration: none;
}

a:visited {
	color: rgb(51, 51, 42);
	text-decoration: none;
}

a:hover {
	color: rgb(51, 51, 42);
	text-decoration: underline;
}

a:active {
	color: rgb(51, 51, 42);
	text-decoration: underline;
}

.manager-link {
	color: rgb(250, 0, 0);
	text-decoration: none;
}

.manager-link:visited {
	color: rgb(250, 0, 0);
	text-decoration: none;
}

.menu-bold {
	font-weight: bold;
}

/** ########## **/
.search-hit {
	margin-bottom: 18px;
	padding: 12px;
	border: 1px solid #bbb5af;
	border-radius: 6px;
	background-color: rgb(255, 255, 243);
	color: rgb(51, 51, 42);
}

.search-hit .highlight {
	background-color: lightgreen;
}

.container {
	width: 100%;
	max-width: 100%;
}

.top-spacer {
	padding-top: 15px;
}

.footer-spacer {
	padding-top: 50px;
}

.text-block {
	background-color: rgb(245, 238, 203);
	border-color: rgb(187, 181, 175);
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.global-background {
	width: 100%;
	max-height: 400px;
	padding: 0px;
	margin: 0px;
	/**background-image: url('/jfb-global-background.jpg')*/
}

.header-background {
	/**background-image: url('/jfb-header-background.jpg');*/
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 400px;
	padding: 0px;
}

.logo-position {
	z-index: 9;
	position: absolute;
	margin-left: 6.5%;
	margin-top: 1.5%;
}

.header-image-position {
	padding-bottom: 0;
	padding-top: 87px;
}

.nav-position {
	z-index: 99;
	position: absolute;
}

.footer-margin {
	margin-top: 14px;
}

.border-left-right {
	border-left: 1px solid;
	border-right: 1px solid;
	border-color: #bbb5af;
}

.cementeries-list {
	margin-top: 25px;
}

.maker-img-space {
	padding: 3px;
	margin: 1px;
	border: 1px solid #bbb5af;
}

@media only screen and (min-width: 800px) {
	.text-spacer {
		padding-left: 130px;
		padding-right: 130px;
	}
	.desecration-content-border {
		border: 1px solid rgb(200, 200, 200);
		margin: 1px;
		margin-top: 10px;
		padding: 10px;
	}
}

@media only screen and (max-width: 1000px) {
	.global-background {
		max-height: 0px;
	}
	.header-background {
		max-height: 0px;
	}
	.logo-position {
		z-index: 9;
		margin-left: 6.5%;
		margin-top: 5%;
	}
	.mobile-view {
		display: none;
	}
	.border-left-right {
		border-left: 0px;
		border-right: 0px;
	}
	.content-layout {
		margin-top: 100px;
	}
}

.content-indention-100 {
	padding-left: 100px;
	padding-right: 100px;
}

.imageGalery {
	display: inline-block;
}

.image-galerie-container {
  width: 200px; /* Feste Breite */
  height: 150px; /* Feste Höhe */
  overflow: hidden; /* Verhindert, dass das Bild über den Container hinausragt (optional) */
}



.image-galerie-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Oder contain, je nach Bedarf */
  padding: 1px;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(#FFFFFF, var(--bs-bg-opacity)) !important;
}

.badge-primary {
	color: white;
	background-color: orchid;
}

.breadcrumb-item-style.active {
	color: orchid;
}

.areaButtonLine {
	border: 1px solid rgb(229, 229, 229);
	background-color: rgb(244, 244, 244);
	margin: 5px;
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 5px;
}

#header-image {
	padding-top: 6%;
	margin-left: auto;
	margin-right: 0;
	display: flex;
	z-index: 0;
	width: 80%;
}

#header-logo {
	z-index: 2;
}

#logo {
	background-color: #cdcdcd;
	z-index: 2;
	padding-left: 10%;
}

.footer-text {
	font-size: 12px;
	line-height: 16px;
	color: rgb(51, 51, 42);
}

.consent-info {
	border: 1px solid rgb(229, 229, 229);
	background-color: rgb(255, 190, 111);
	margin: 5px;
	margin-top: 10px;
	text-align: center;
}